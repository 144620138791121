import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Link } from '@material-ui/core';
import { useHistory } from "react-router-dom";

import { SearchInput } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));



const UsersToolbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const history = useHistory();

  const routeChange = () => {
    let path = '/eventos/admin';
    history.push(path);
  }

  const onSelfRegistration = () => {
    let path = "/selfregistration";
    window.location.href = window.location.href + path;
  }

  const onSelfRegistrationLector = () => {
    let path = "/selfregistrationkeyboard";
    window.location.href = window.location.href + path;
  }


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
         {/*
        <Button className={classes.importButton} color="primary" variant="outlined" onClick={() => onSelfRegistration()}>Auto-Camara</Button>
        <Button className={classes.exportButton} color="primary" variant="outlined" onClick={() => onSelfRegistrationLector()}>Auto-Lector</Button>
        */}
        <Button
          color="primary"
          variant="contained"
          onClick={routeChange}
        >
          Alta evento
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Buscar evento"
        />
      </div>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
