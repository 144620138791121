import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getAttendeeDetails, logAttendeeBadgePrint, updateExternalId } from './actions'
import { Typography } from '@material-ui/core';
import { Stepper, Step, StepLabel } from "@material-ui/core";
import QrReader from 'react-qr-reader';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography as MuiTypography } from '@material-ui/core';
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import * as bpac from 'bpac-react';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6)
  }
}));

/*
const defaultProps = {
  m: 1,
  border: 1,
  style: { width: '40vw', height: '45vh' },
  alignItems: 'center',
  justifyContent: 'center',
  display: "flex",
  //bgcolor:"lightgreen"
};
*/

const SelfRegistration = props => {



  const { width } = useWindowDimensions();
  const { className, ...rest } = props;
  const classes = useStyles();

  //const [result, setResult] = useState("No result");
  const [attendeeName, setAttendeeName] = useState("");
  const [attendeeMessage, setAttendeeMessage] = useState("Por favor, acerque el codigo QR dentro del cuadro rojo");
  //const [event, setEvent] = useState({});
  const [step, setStep] = useState(0);

  //const [guid, setGuid] = useState("");
  //const [attendeeid, setAttendeeId] = useState("");
  const [categoria, setCategoria] = useState("");
  const [event, setEvent] = useState("");

  const messages = ["Muestra el codigo QR que te enviamos por correo electronico durante tu registro en linea",
    "Toma la etiqueta de la impresora y pegala en el gafete",
    "Listo!"
  ];


  const handleScan = async (data) => {
    if (data) {
      try {
        if (step === 0) {
          setStep(1);
          var attendee_guid = data;
          let attendee = (await getAttendeeDetails(attendee_guid)).data;
          console.log(attendee);

          if (attendee !== null) {
            let nombre = attendee.nombres + " " + attendee.apellidos;
            setAttendeeMessage("Bienvenido " + nombre + " 👏");
            setAttendeeName(nombre);
            setCategoria(attendee.categoria);
            setEvent(attendee.event);

            await printBadge(attendee);
            await logAttendeeBadgePrint(attendee._id, attendee_guid);
          }
        }
        else {
          setAttendeeMessage("El usuario no esta registrado en el evento 😔");
        }
      }
      catch (error) {
        console.log(error);
        setAttendeeMessage("Error! no fue posible procesar la entrada 🛑");
      }
      finally {
        // Your application has indicated there's an error
        window.setTimeout(function () {

          // Move to a new location or you can do something else
          window.location.reload(false);

        }, 6000);
      }
    }
  }

  const printBadge = async (attendee) => {
    const id = attendee._id;
    const nombres = attendee.nombres;
    const apellidos = attendee.apellidos;

    if (id) {
      const objDoc = bpac.IDocument;
      const ret = await objDoc.Open("https://exmis.apptl.mx/qr3.lbx");

      if (ret === true) {
        // Set the text in a Text object inside your template file
        (await objDoc.GetObject("QR")).Text = id;
        (await objDoc.GetObject("Nombre")).Text = nombres;
        (await objDoc.GetObject("Apellido")).Text = apellidos;

        // Start the print
        await objDoc.StartPrint('', 0);
        await objDoc.PrintOut(1, 0);
        await objDoc.EndPrint();
        await objDoc.Close();
      }
    }
  }


  const handleError = err => {
    console.error(err)
  }


  const previewStyle = {
    width: (width < 768) ? '50%' : '30%'
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <div align="center">
            <Typography variant="h1">
              Bienvenido! Por favor sigue las instrucciones mostradas en pantalla para obtener tu gafete de ingreso al evento
            </Typography>
            <br></br>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div align="center">
            <Stepper alternativeLabel activeStep={step}>
              <Step>
                <StepLabel>Muestra el codigo QR que te enviamos por correo electronico durante tu registro en linea</StepLabel>
              </Step>
              <Step>
                <StepLabel>Toma la etiqueta de la impresora y pegala en el gafete</StepLabel>
              </Step>
              <Step completed>
                <StepLabel>Listo!</StepLabel>
              </Step>
            </Stepper>
            <br></br>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div align="center" >
            {step !== 2 ?
              <>
                {step === 0 ?
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h3">
                        Paso {step + 1}. {messages[step]}
                      </Typography>
                      <br></br>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h1">
                        {attendeeMessage}
                      </Typography>
                      <br></br>
                    </Grid>
                    <Grid item xs={12}>
                      <QrReader
                        delay={200}
                        onError={handleError}
                        onScan={handleScan}
                        style={previewStyle}
                      />
                    </Grid>
                  </>
                  :
                  <>
                    <motion.div
                      className="box"
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        default: {
                          duration: 0.3,
                          ease: [0, 0.71, 0.2, 1.01]
                        },
                        scale: {
                          type: "spring",
                          damping: 5,
                          stiffness: 100,
                          restDelta: 0.001
                        }
                      }}
                    >
                      <Grid item xs={12}>
                        {
                          (categoria === "COMPRADOR" && event === "TECNO") ?
                            <>
                              <Typography variant="h3" display="inline">
                                Paso {step + 1}. Toma uno de los gafetes de
                              </Typography>&nbsp;
                              <Typography variant="h3" style={{ color: '#eb8f34' }} display="inline">
                                <b>COMPRADOR NARANJA</b>
                              </Typography>&nbsp;
                              <Typography variant="h3" display='inline'>
                                y pegale la etiqueta que se imprimio
                              </Typography>
                              <br></br>
                            </> :
                            <></>
                        }
                        {
                          (categoria === "COMPRADOR" && event !== "TECNO") ?
                            <>
                              <Typography variant="h3" display="inline">
                                Paso {step + 1}. Toma uno de los gafetes de
                              </Typography>&nbsp;
                              <Typography variant="h3" style={{ color: '#ed1932' }} display="inline">
                                <b>COMPRADOR ROJO</b>
                              </Typography>&nbsp;
                              <Typography variant="h3" display='inline'>
                                y pegale la etiqueta que se imprimio
                              </Typography>
                              <br></br>
                            </>
                            :
                            <>
                            </>
                        }
                        {
                          (categoria !== "COMPRADOR") ?
                            <>
                              <Typography variant="h3" display="inline">
                                Paso {step + 1}. Toma uno de los gafetes de
                              </Typography>&nbsp;
                              <Typography variant="h3" style={{ color: '#01aaaf' }} display="inline">
                                <b>VISTANTE AZUL VERDE</b>
                              </Typography>&nbsp;
                              <Typography variant="h3" display='inline'>
                                y pegale la etiqueta que se imprimio
                              </Typography>
                              <br></br>
                            </>
                            :
                            <></>
                        }
                        <br></br>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h1">
                          {attendeeMessage}
                        </Typography>
                        <br></br>
                      </Grid>
                      <Grid item xs={12}>
                        <QrReader
                          delay={200}
                          onError={handleError}
                          onScan={handleScan}
                          style={previewStyle}
                        />
                      </Grid>
                    </motion.div>
                  </>
                }
              </>
              :
              <>
                <motion.div
                  className="box"
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    default: {
                      duration: 0.3,
                      ease: [0, 0.71, 0.2, 1.01]
                    },
                    scale: {
                      type: "spring",
                      damping: 5,
                      stiffness: 100,
                      restDelta: 0.001
                    }
                  }}
                >
                  <Grid item xs={12}>
                    <Typography variant="h1">
                      Felicidades {attendeeName}! Tu registro fue realizado satisfactoriamente 👏
                    </Typography>
                  </Grid>
                </motion.div>
              </>
            }
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SelfRegistration.prototype = {
  className: PropTypes.string
};

export default SelfRegistration;

